import { 
    HUB_CONNECT_SUCCESS, 
    HUB_CONNECT_FAILURE, 
    HUB_SUBSCRIBE_FAILURE, 
    HUB_SUBSCRIBE_SUCCESS, 
    HUB_RECEIVE_DATA, 
    HUB_DISCONNECT
} from "../actions/action.types";

const hub = (
    state = {
        isConnected: false,
        isSubscribed: false,
        data: null,
        error: null,
    },
    action = {}
) => {
    switch (action.type) {
        case HUB_CONNECT_SUCCESS:
            return { ...state, isConnected: true, error: null };
        case HUB_CONNECT_FAILURE:
            return { ...state, isConnected: false, error: action.payload };
        case HUB_SUBSCRIBE_SUCCESS:
            return { ...state, isSubscribed: true, error: null };
        case HUB_SUBSCRIBE_FAILURE:
            return { ...state, isSubscribed: false, error: action.payload };
        case HUB_RECEIVE_DATA:
            return { ...state, data: action.payload };
        case HUB_DISCONNECT:
            return { ...state, isConnected: false, isSubscribed: false, data: null, error: null };
        default:
            return state;
    }
}

export default hub;