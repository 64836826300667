import { Container, Typography } from '@mui/material'
import React from 'react'

const NotFound = () => {
  return (
    <Container>
      <Typography variant='h1' color='textPrimary'>404</Typography>
      <Typography variant='h1' color='textPrimary'> Not Found</Typography>
    </Container>
  )
}

export default NotFound
