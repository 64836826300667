import { 
    HUB_CONNECT, 
    HUB_CONNECT_FAILURE, 
    HUB_CONNECT_SUCCESS, 
    HUB_DISCONNECT, 
    HUB_RECEIVE_DATA, 
    HUB_SUBSCRIBE, 
    HUB_SUBSCRIBE_FAILURE, 
    HUB_SUBSCRIBE_SUCCESS 
} from "./action.types";


export const hubConnect = (id) => ({ type: HUB_CONNECT, id });
export const hubConnectSuccess = () => ({ type: HUB_CONNECT_SUCCESS });
export const hubConnectFailure = (error) => ({ type: HUB_CONNECT_FAILURE, payload: error });

export const hubDisconnect = () => ({ type: HUB_DISCONNECT });

export const hubSubscribe = () => ({ type: HUB_SUBSCRIBE });
export const hubSubscribeSuccess = () => ({ type: HUB_SUBSCRIBE_SUCCESS });
export const hubSubscribeFailure = (error) => ({ type: HUB_SUBSCRIBE_FAILURE, payload: error });

export const hubReceiveData = (data) => ({ type: HUB_RECEIVE_DATA, payload: data });
