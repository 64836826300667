import { FETCH } from "../actions/action.types";
import { isEqual, get } from "lodash";
import { logOut } from "../actions/action.auth";

export default ({ http }) => {
  const actionIndex = {};
  const baseUrl = 'https://localhost:7248';

  const key = (action) => action.key || action.actionType;

  const actionData = (action) => action.actionData || {};

  const inProgress = (action) => {
    const candidate = actionIndex[key(action)];
    return (
      candidate &&
      candidate.url === action.url &&
      isEqual(candidate.payload, action.payload)
    );
  };

  const handleResponse =
    (action, dispatch, createResponseAction, isSuccess) => (res) => {
      const responseAction = createResponseAction(action, res);
      if (action.takeAll) {
        dispatch(responseAction);
      } else if (actionIndex[key(action)] === action) {
        delete actionIndex[key(action)];
        dispatch(responseAction);
      }
      if (isSuccess && action.onSuccess) {
        action.onSuccess(responseAction);
      } else if (!isSuccess && action.onError) {
        action.onError(responseAction);
      }
    };

  const requestAction = (baseAction) => ({
    type: baseAction.actionType,
    ...actionData(baseAction),
  });

  const successAction = (baseAction, res) => ({
    type: `${baseAction.actionType}_SUCCESS`,
    data: res.data,
    ...actionData(baseAction),
  });

  const errorAction = (baseAction, err) => ({
    type: `${baseAction.actionType}_ERROR`,
    error: err.message,
    status: get(err, ["response", "status"]),
    data: get(err, ["response", "data"]),
    ...actionData(baseAction),
  });

  return (store) => (next) => (action) => {
    if (!action || action.type !== FETCH) {
      return next(action);
    }
    if (inProgress(action)) {
      return;
    }
    store.dispatch(requestAction(action));

    if (!action.takeAll) {
      actionIndex[key(action)] = action;
    }

    const auth = store.getState().auth;

    http({
      method: action.method || "get",
      url: baseUrl + action.url,
      headers: {
        Authorization: `Bearer ${auth.token}`
      },
      data: action.payload,
    })
      .then(handleResponse(action, store.dispatch, successAction, true))
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          console.error("Unauthenticated");
          store.dispatch(logOut());
          delete actionIndex[key(action)];
          return;
        }
        handleResponse(action, store.dispatch, errorAction, false)(err);
      });
  };
};