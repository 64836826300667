import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, Navigate, Outlet } from 'react-router-dom';

const RequireAuth = () => {
    const token = useSelector(state => state.auth.token);
    const location = useLocation();
  return (
    token ?
    <Outlet />
    : <Navigate to="/login" state={{from: location}} replace />
  )
}

export default RequireAuth
