import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from '../actions/action.auth';

function NavBar() {
    const [open, setOpen] = React.useState(false);
    
    const dispatch = useDispatch();

    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    const navbarState = useSelector((state) => state.navbar);

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', pt: 1, width: '20em' }}>
            <Typography variant="h6" sx={{ my: 2, px: 2 }}>
                AEM REMOTE
            </Typography>
            <List>
                <Link to='/' style={{ textDecoration: 'none', color: 'white' }}>
                    <ListItemButton sx={{ color: 'primary.main', borderTop: 'solid white 1px', borderBottom: 'solid white 1px'}}>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                </Link>
                {/* <Link to='/clients' style={{ textDecoration: 'none', color: 'white' }}>
                    <ListItemButton sx={{ color: 'primary.main', borderBottom: 'solid white 1px' }}>
                        <ListItemText primary="Clients" />
                    </ListItemButton>
                </Link> */}
                <Link to='/systems' style={{textDecoration: 'none', color: 'white'}}>
                    <ListItemButton sx={{ color: 'primary.main', borderBottom: 'solid white 1px' }}>
                        <ListItemText primary="Systems" />
                    </ListItemButton>
                </Link>
                <ListItemButton sx={{ color: 'primary.main', borderBottom: 'solid white 1px' }} onClick={(event) => {
                    dispatch(logOut())
                }}>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
        </Box>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                    <Typography variant="h6" component="div" >
                        AEM REMOTE 
                    </Typography>
                    <Typography variant="h6" component="div" sx={{mb: '2px', mx: 1}}>
                        ○ 
                    </Typography>
                    <Typography variant="h6" sx={{ my: 2 }}>
                        {navbarState.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={open}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

export default NavBar;
