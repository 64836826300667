import { CHANGE_NAVBAR_TITLE 
} from "../actions/action.types";

const navbar = (
    state = {
        title: ""
    },
    action = {}
) => {
    switch (action.type) {
        case CHANGE_NAVBAR_TITLE: {
            return { 
                ...state,
                title: action.payload
            };
        }
        default:
            return state;
    }
}

export default navbar;