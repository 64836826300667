import React from 'react'
import './LampIndicatorInstrument.css'
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const LampIndicatorInstrument = ({ active, activeColor }) => {
  return (
    <Box sx={{ width: { xl: '60px', lg: '40px', md: '30px', sm: '20px', xs: '15px' }, height: { xl: '60px', lg: '40px', md: '30px', sm: '20px', xs: '15px' } }} className={active ? activeColor : 'inactive'}></Box>
  )
}

LampIndicatorInstrument.propTypes = {
  active: PropTypes.bool.isRequired,
  activeColor: PropTypes.string.isRequired
};

export default LampIndicatorInstrument
