import { SET_CREDENTIALS, LOGOUT, LOGIN } from "./action.types"

export const login = (username, password) => ({
        type: LOGIN,
        username,
        password
});

export const setCredentials = () => ({
    type: SET_CREDENTIALS
});

export const logOut = () => ({
    type: LOGOUT
});