import { combineReducers } from 'redux';
import clients from './reducer.clients';
import systems from './reducer.systems';
import navbar from './reducer.navbar';
import auth from './reducer.auth';
import hub from './reducer.hub';

export default combineReducers({
    clients,
    systems,
    navbar,
    auth,
    hub
});
