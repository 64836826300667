import { FETCH_CLIENTS, 
    FETCH_CLIENTS_ERROR, 
    FETCH_CLIENTS_SUCCESS 
} from "../actions/action.types";

const clients = (
    state = {
        data: [],
        isLoading: false,
        error: null
    },
    action = {}
) => {
    switch (action.type) {
        case FETCH_CLIENTS: {
            return { 
                ...state,
                isLoading: true
            };
        }
        case FETCH_CLIENTS_SUCCESS: {
            return { 
                ...state, 
                isLoading: false,
                data: action.data
            };
        }
        case FETCH_CLIENTS_ERROR: {
            return { 
                ...state, 
                isLoading: false,
                error: action.error
            };
        }
        default:
            return state;
    }
}

export default clients;