import { fork } from 'redux-saga/effects';
import { authSaga } from './auth.saga';
import { hubSaga } from './hub.saga';

function* rootSaga() {
  yield fork (authSaga);
  yield fork (hubSaga);
}

export default rootSaga;
