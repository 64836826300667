export const FETCH = "FETCH";

export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS";
export const FETCH_CLIENTS_ERROR = "FETCH_CLIENTS_ERROR";

export const FETCH_SYSTEMS = "FETCH_SYSTEMS";
export const FETCH_SYSTEMS_SUCCESS = "FETCH_SYSTEMS_SUCCESS";
export const FETCH_SYSTEMS_ERROR = "FETCH_SYSTEMS_ERROR";

export const CHANGE_NAVBAR_TITLE = "CHANGE_NAVBAR_TITLE";

export const SET_CREDENTIALS = "SET_CREDENTIALS";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";

export const HUB_CONNECT = 'HUB_CONNECT';
export const HUB_CONNECT_SUCCESS = 'HUB_CONNECT_SUCCESS';
export const HUB_CONNECT_FAILURE = 'HUB_CONNECT_FAILURE';

export const HUB_DISCONNECT = 'HUB_DISCONNECT';

export const HUB_SUBSCRIBE = 'HUB_SUBSCRIBE';
export const HUB_SUBSCRIBE_SUCCESS = 'HUB_SUBSCRIBE_SUCCESS';
export const HUB_SUBSCRIBE_FAILURE = 'HUB_SUBSCRIBE_FAILURE';

export const HUB_RECEIVE_DATA = 'HUB_RECEIVE_DATA';