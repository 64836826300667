import { call, fork, put, takeLeading, getContext } from "redux-saga/effects";
import { LOGIN, LOGIN_ERROR, LOGIN_SUCCESS, SET_CREDENTIALS } from "../actions/action.types";

function* loginWatcher() {
    yield takeLeading(LOGIN, loginSaga)
}

function* loginSaga({ username, password }) {
    try {
        const http = yield getContext("http");
        const { data } = yield call(
            http.post,
            `https://localhost:7248/auth`,
            {
                username,
                password
            }
        );
        yield put({ type: SET_CREDENTIALS, token: data, user: null });
        yield put({ type: LOGIN_SUCCESS });
    } catch(err) {
        yield put({ type: LOGIN_ERROR, error: err.response.data });
        console.log(err.response)
    }
    

}

export function* authSaga() {
    yield fork(loginWatcher);
}