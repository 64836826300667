import * as signalR from "@microsoft/signalr";

let connection = null;

export const createSignalRConnection = (url, token) => {
  connection = new signalR.HubConnectionBuilder()
    .withUrl(url, {
        accessTokenFactory: () => token
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  return connection;
};

export const getConnection = () => {
    return connection;
};

export const connectSignalR = async () => {
  if (connection) {
    await connection.start();
  }
};

export const subscribeToEvents = async (id) => {
  if (connection) {
    // Assume `Subscribe` is the server-side event for subscribing
    await connection.invoke("Subscribe", id);
  }
};

export const listenToReceiveData = (callback) => {
    if (connection) {
        connection.on("ReceiveData", callback);
    }
};

export const disconnectSignalR = async () => {
    if (connection) {
      await connection.stop();
    }
};