import React, { useState } from 'react';
import { List, ListItemButton, ListItemText, ListItemIcon, Collapse, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function DropdownList({title, subtitle, icon, children}) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List sx={{bgcolor: "primary.sub", borderRadius: 5}} component="nav" aria-labelledby="nested-list-subheader">
            <ListItemButton  onClick={handleClick}>
                <ListItemIcon>
                    {icon}
                    {/* <PersonOutlineIcon sx={{fontSize: 80}}/> */}
                </ListItemIcon>
                <ListItemText sx={{mx: 3}}
                    primary={<Typography variant='h3' sx={{color: "primary.main" }}>{title}</Typography>} 
                    secondary={subtitle} />
                {open ? <ExpandLessIcon sx={{color: "primary.main"}} /> : <ExpandMoreIcon sx={{color: "primary.main"}} />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </List>
    );
}

export default DropdownList;
