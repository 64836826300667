import './App.css';
import { Route, Routes } from 'react-router-dom';
import HomeView from './views/HomeView';
import ClientsView from './views/ClientsView';
import NotFound from './views/NotFound';
import NavbarLayout from './layouts/NavbarLayout';
import LoginView from './views/LoginView';
import RequireAuth from './components/RequireAuth';
import SystemsView from './views/SystemsView';
import RealTimeSystemView from './views/RealTimeSystemView';

function App() {
  return (
    <>
      <Routes>
        <Route element={<RequireAuth />}>
          <Route element={<NavbarLayout />}>
            <Route path='/' element={<SystemsView />}/>
            {/* <Route path='/clients' element={<ClientsView />}/> */}
            <Route path='/systems' element={<SystemsView />}/>
          </Route>
          <Route path='/realtime/:id' element={<RealTimeSystemView />}/>
        </Route>
        <Route path='/login' element={<LoginView />}/>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
