import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter } from "react-router-dom";
import store from './store';
import { Provider } from 'react-redux';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
      sub: '#009a9a'
    },
    secondary: {
      main: '#025e7e',
    },
    background: {
      paper: '#009a9a',
      secondary: '#e6f3e7',
    },
    text: {
      primary: '#ffffff',
      secondary: '#e6f3e7',
    },
  },
  typography: {
    fontFamily: '"Fira Sans", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem', // Default font size
      '@media (min-width:600px)': {
        fontSize: '3rem', // Font size for medium devices
      },
      '@media (min-width:960px)': {
        fontSize: '3.5rem', // Font size for large devices
      },
      '@media (min-width:1280px)': {
        fontSize: '4.5rem', // Font size for extra large devices
      },
    },
    h2: {
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      },
      '@media (min-width:960px)': {
        fontSize: '3rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '4rem',
      },
    },
    h3: {
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
      '@media (min-width:960px)': {
        fontSize: '3rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '3.5rem',
      },
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>  
    <ThemeProvider theme={theme}>
      <Provider store={store()}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider> 
  </React.StrictMode>
);

