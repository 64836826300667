import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function PageHeader({title}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box sx={{m: 5}}>
            <Typography variant={isMobile ? 'h2' : 'h1'} color='textPrimary'>{title}</Typography>
        </Box>
    )
}

export default PageHeader
